import { AppNav, AppNavItemKeys } from '@shared/models/config';

export const getDefaultPath = (appUrl: string, appNav: AppNav) => {
  return getTransformedRoute(appUrl, appNav[appNav.default_page as AppNavItemKeys].url);
};

export const getTransformedRoute = (appUrl: string, url: string): string => {
  if (isReactUrl(appUrl, url)) {
    return url.slice(appUrl.length);
  }

  return url;
};

export const isReactUrl = (appUrl: string, url: string): boolean => url.startsWith(appUrl);
